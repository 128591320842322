import { Link, makeStyles } from '@material-ui/core';
import { memo } from 'react';

import { useUser } from '../hooks/useUser';
import { IconType } from '../modules/data';
import { CountryCode, FirstCallType, ListCarDTO, SourceType } from '../modules/generated/api';
import WithChildren from '../types/WithChildren';
import HighlightIcon from './HighlightIcon';

type HighlightsProps = {
  car: ListCarDTO;
};

const useStyles = makeStyles(({ spacing }) => ({
  highlightsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: spacing(12.5),
  },
}));

const Highlights = ({
  car: {
    colorId,
    countryOrigin,
    equipmentKeys,
    assessmentLink,
    color,
    statusReportLink,
    firstCallType,
    virtualSources,
    promotions,
  },
  children,
}: WithChildren<HighlightsProps>) => {
  const { data: user } = useUser();
  const classes = useStyles();

  return (
    <div className={classes.highlightsWrapper}>
      {assessmentLink && statusReportLink && (
        <Link target="_blank" rel="noreferrer" href={assessmentLink}>
          <HighlightIcon type={IconType.ASSESSMENT} value="1" />
        </Link>
      )}
      {assessmentLink && !statusReportLink && (
        <Link target="_blank" rel="noreferrer" href={assessmentLink}>
          <HighlightIcon type={IconType.ASSESSMENT} value="default" />
        </Link>
      )}
      {statusReportLink && (
        <Link target="_blank" rel="noreferrer" href={statusReportLink}>
          <HighlightIcon type={IconType.ASSESSMENT} value="2" />
        </Link>
      )}
      {colorId && <HighlightIcon type={IconType.COLOR} value={colorId} label={color} />}
      {countryOrigin && <HighlightIcon type={IconType.COUNTRY} value={countryOrigin} />}
      {virtualSources?.includes(SourceType.Vvj) && (
        <HighlightIcon type={IconType.VIRTUAL_SOURCE} value={SourceType.Vvj} />
      )}
      {firstCallType && (
        <HighlightIcon
          type={IconType.VIRTUAL_SOURCE}
          value={firstCallType === FirstCallType.Own ? SourceType.RealFirstCallOwn : SourceType.RealFirstCallOther}
        />
      )}
      {!!promotions?.includes('FOKUS_MODELL') && <HighlightIcon type={IconType.PROMOTION} value="FOKUS_MODELL" />}
      {!!promotions?.includes('BONUS_MODELL') && user?.dealer?.country !== CountryCode.De && (
        <HighlightIcon type={IconType.PROMOTION} value="BONUS_MODELL" />
      )}
      {(equipmentKeys || []).map((equipment: string) => (
        <HighlightIcon key={equipment} type={IconType.EQUIPMENT} value={equipment} />
      ))}
      {children}
      {promotions?.includes('THERMO') && <HighlightIcon type={IconType.SPECIAL} value="THERMO" />}
    </div>
  );
};

export default memo(Highlights);
